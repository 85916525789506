<template>
  <v-container fluid class="pt-0">
    <v-row dense>
      <v-col cols="12">
        <v-card>
          <v-toolbar color="color1 color1Text--text">
            <v-toolbar-title>Rostered Teams</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-text-field
              v-model="search"
              append-icon="fas fa-search"
              label="Search"
              single-line
              hide-details clearable
            ></v-text-field>
          </v-card-text>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="teams"
              class="elevation-1"
              :loading="loading"
              :search="search"
            >
              <template v-slot:[`item.name`]="{item}">
                <div class="d-flex align-center">
                  <v-img
                    :src="item.logoUrl"
                    max-height="50px"
                    max-width="50px"
                    contain
                    class="mr-3"
                  ></v-img>
                  {{item.name}}
                </div>
              </template>
              <template v-slot:[`item.action`]="{item}">
                <v-btn color="color3 color3Text--text" :to="`/team/${item.id}`" small fab>
                  <v-icon>fas fa-caret-right</v-icon>
                </v-btn>
              </template>
              <template v-slot:[`item.dtModified`]="{item}">
                {{item.dtModified | localDt}}
              </template>
            </v-data-table>
            <v-col cols="12" class="text-center">
              <v-btn color="color3" text to="/team/new">Add new Team</v-btn>
            </v-col>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      teams: [],
      loading: false,
      search: null,
      headers: [
        { text: 'Id', value: 'id' },
        { text: 'Team', value: 'name' },
        { text: 'Type', value: 'type' },
        { text: 'Players', value: 'players', align: 'center' },
        { text: 'Last Modified', value: 'dtModified' },
        { text: '', value: 'action', align: 'right' }
      ]
    }
  },
  methods: {
    loadTeams () {
      this.loading = true
      this.$VBL.roster.teams()
        .then(r => { this.teams = r.data })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    }
  },
  mounted () {
    this.loadTeams()
  }
}
</script>
